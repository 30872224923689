* {
  box-sizing: border-box;
}

body {
  color: #222;
}

img {
  max-width: 100%;
  max-height: 30vh;
}

main {
  padding: 10px;
  max-width: 800px;
  margin: 0 auto;
}

header {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 50px;
  align-items: center;
}

header a {
  text-decoration: none;
  color: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

header a.logo {
  font-weight: bold;
  font-size: 1.4rem;
}

header a img {
  max-height: 50px;
  object-fit: contain;
}

header a p {
  visibility: collapse;
}

header nav {
  display: flex;
  gap: 15px;
}

div.post {
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr;
  gap: 20px;
  margin-bottom: 30px;
}

@media screen and (min-width: 600px) {
  div.post {
    grid-template-columns: 0.9fr 1.1fr;
  }

  header {
    flex-direction: row;
  }

  header a p {
    visibility: visible;
  }

}

div.post div.texts h2 {
  margin: 0;
  font-size: 2rem;
}

div.post div.texts a {
  color: inherit;
  text-decoration: none;
}

div.post p.info {
  margin: 6px 0;
  color: #888;
  font-size: .7rem;
  font-weight: bold;
  display: flex;
  gap: 10px;
  align-items: center;
}

div.post p.info a.author {
  color: #333;
}

div.post p.description {
  margin: 10px 0;
  line-height: 1.5rem;
}

form.login form.register {
  max-width: 400px;
  margin: 0 auto;
}

input {
  display: block;
  margin-bottom: 5px;
  width: 100%;
  padding: 5px;
  border: 2px solid #ddd;
  background-color: #fff;
}

button {
  width: 100%;
  display: block;
  background-color: #555;
  border: 0;
  color: #fff;
  border-radius: 5px;
  padding: 7px 0;
}

form.login h1,
form.register h1 {
  text-align: center;
}

div.post-page div.image {
  max-height: 50vh;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}

div.post-page div.image img {
  object-fit: cover;
  object-position: center center;
}

div.post-page a {
  color: #333;
  text-decoration: underline;
}

div.post-page h1 {
  text-align: center;
}

div.post-page time {
  text-align: center;
  margin: 10px 0 5px;
  display: block;
  font-size: 0.8rem;
  color: #aaa;
}

div.post-page div.author {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1rem;
  font-weight: bold;
}

div.post-page p {
  text-align: left;
}

div.edit-row {
  text-align: center;
  margin-bottom: 20px;
}

a.edit-btn {
  background-color: #aaa;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  padding: 15px 10px;
  border-radius: 5px;
}

a svg {
  color: #333;
  height: 25px;
}

div.edit-image-current {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    object-fit: contain;
    margin: 10px;
    min-width: 100%;
  }
}

div.edit-image-new {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  img {
    object-fit: contain;
    margin: 10px;
    min-width: 100%;
  }
}
